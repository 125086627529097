import React from 'react'
import SignIn from '../SignIn'
import NavBar from '../Navbar'

const SigninPage = () => {
    return (
        
            <>
                <NavBar />
                <SignIn />
            </>
        
    )
}

export default SigninPage;
